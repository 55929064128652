import React from "react"
import { I18NextWrapper } from "../loaders/i18nextInit"
import "../styles/index.css"
import { Loading } from "../components/Loading"
import { Col, Row } from "antd"
import { sectionLayout } from "../utils/constants"
import CONCURS_FRONT from "../images/concurs_front.png"
import CONCURS_BACK from "../images/concurs_back.png"

const Concurs = () => {
  return (
    <I18NextWrapper loading={<Loading />}>
      <Row>
        <Col {...sectionLayout}>
          <h1 style={{ margin: "2rem 0" }}>
            Scapă de gunoi în mod inteligent cu Ara - aplicația ta pentru
            colectare, sortare și reciclare!
          </h1>

          <img
            src={CONCURS_FRONT}
            style={{ width: "100%" }}
            alt="concurs front"
          />

          <img
            src={CONCURS_BACK}
            style={{ width: "100%" }}
            alt="concurs back"
          />

          <iframe
            width="100%"
            height="80%"
            src="https://docs.google.com/document/d/e/2PACX-1vTAuUkQpMpvEpkm1jDwGEI1obzeqqYtL7xyEr-YW0Yky8erdLZ18iStR23j0uAvw89-5e53EVPXOtR6/pub?embedded=true"
          ></iframe>
        </Col>
      </Row>
    </I18NextWrapper>
  )
}

export default Concurs
